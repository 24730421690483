import WebServiceRequest from "./WebServiceRequest";

class TransactionCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Transaction/Create");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class TransactionUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Transaction/Update");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class TransactionGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Transaction/Get");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class TransactionGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Transaction/GetAll");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}

class TransactionCancelWithdrawRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Transaction/CancelWithdraw");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
export {
  TransactionCreateRequest,
  TransactionUpdateRequest,
  TransactionGetRequest,
  TransactionGetAllRequest,
  TransactionCancelWithdrawRequest,
};
